<template>
<div class="advantages">
    <div class="all-top">
        <div class="all-top-bg">
            <img src="@/assets/img/stockbg.svg" alt="">
        </div>
        <h1 data-aos="fade-up"> {{Page.data[`title_${$i18n.locale}`]}} </h1>
        <p data-aos="fade-up"> {{Page.data[`description_${$i18n.locale}`]}}</p>
        
    </div>
    <div class="advantage-list">
        <div class="advantage-item" data-aos="fade-up" v-for="(item, i) in Page.data.about" :key="i">
            <div class="advantage-item-text">
                <h1> {{item[`title1_${$i18n.locale}`]}} </h1>
                <h2>{{item[`title2_${$i18n.locale}`]}}  </h2>
                <p> {{item[`text_${$i18n.locale}`]}}  </p>
            </div>
            <div class="advantage-item-img" >
                <img :src="`https://avior.prestigeautofl.com${Page.files[`aboutImage${item.id}`]}`" alt="">
            </div>
        </div>
    </div>
    <div class="flex w-full justify-center advantage-template" v-if="Page.data.linkes">
        <div class="advantege-linkes w-full mw-1920" >
            <SystemLinkes />
        </div>
    </div>
    <div class="flex w-full justify-center advantage-template"  v-if="Page.data.credit">
        <div class="advantege-credi w-full mw-1920" >
            <Credit title="Розрахунок позики" />
        </div>
    </div>
    <div class="flex w-full justify-center advantage-template" v-if="Page.data.contact">
    <div class="advantage-about w-full mw-1920"  >
        <div class="advantage-about-title">
            <h1>Напишіть нам :</h1>
        </div>
        <div class="advantage-about-item">
            <div class="advantate-about-card">
                <ContactCard />
            </div>
            <div class="advantate-about-img">
                <img src="@/assets/img/big-a.svg" alt="">
            </div>
            
        </div>
    </div>
    </div>
</div>
</template>

<script>
import SystemLinkes from '../components/SystemLinks.vue'
import Credit from '../components/Credit.vue'
import ContactCard from '../components/ContactCard.vue'
 export default {
     name: 'advantages',
     components: {
         SystemLinkes,
         Credit,
         ContactCard
     },
     computed:{
         Page(){
             return this.$store.state.Advantages
         }
     },
     data() {
         return {
             input: '',
             kredit_type: 'pledge',
             a: 10,
         }
     },

 }
</script>

<style lang="scss">
.all-top {
    padding: 250px 120px 150px 120px;
    background: var(--bg-top);
    position: relative;
    overflow: hidden;

    h1 {
        font-family: Stem-Medium;
        font-size: 51px;
        line-height: 60px;
        color: var(--other-black);
        position: relative;
        z-index: 1;
    }

    p {
        font-family: Stem-Medium;
        font-size: 24px;
        line-height: 28px;
        color: var(--white-black);
        margin-top: 20px;
        position: relative;
    }

    .all-top-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        justify-content: flex-end;

    }
}
@media screen and(max-width:768px) {
   .all-top {
    padding: 150px 20px 100px 20px; 

    h1 { 
        font-size: 32px;
        line-height: 36px; 
        text-align: center;
    }
    p { 
        font-size: 1.125rem;
        line-height: 1.25rem; 
        text-align: center;
    }
    .all-top-bg {
        display: none;

    }
} 
    
}

.advantage-list {
    padding: 60px;

    background: var(--white-bg);
    .advantage-item {
        display: flex;
        align-items: center;
        

        .advantage-item-text {
            width: 50%;
            padding: 60px;

            h1 {
                font-family: Stem-Medium;
                font-size: 30px;
                line-height: 42px;
                font-feature-settings: 'tnum'on, 'lnum'on;
                color: var(--other-black);
            }

            h2 {
                font-family: Stem-Medium;
                font-size: 24px;
                line-height: 28px;
                margin: 20px 0px;
                font-weight: 400;
                color: var(--white-black);
            }

            p {
                font-family: Stem-Regular;
                font-size: 1.125rem;
                line-height: 27px;
                /* or 150% */

                font-feature-settings: 'tnum'on, 'lnum'on;

                color: var(--white-black);
            }
        }

        .advantage-item-img {
            width: 50%;
            padding: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 90%;
            }
        }
    }
    .advantage-item:nth-child(even){
        flex-direction: row-reverse;
    }

}
.advantege-linkes{
    display: flex; 
    justify-content: center; 
}
.advantege-credit{
    display: flex;
    justify-content: center;
    padding: 120px 0px;
    width: 100%;
}
.advantage-about{ 
    .advantage-about-title{
        padding: 50px 120px 0px 120px;
        
        h1{
            font-family: Stem-Medium;
            font-size: 40px;
            line-height: 42px; 
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: var(--other-black);
        }
    }
    .advantage-about-item{
        display: flex;
        flex-wrap: wrap; 
        .advantate-about-card{
            width: 50%;
            padding: 50px 120px;
        }
        .advantate-about-img{
            display: flex;
            width: 50%;
            padding-top: 50px;
            justify-content: center;
            align-items: flex-end;
            img{
                max-width: 100%;
            }
        }
    }
}
.advantage-template:nth-child(odd){
    background: var(--bg-top);
}
@media screen and(max-width:1250px) {
    .advantage-list { 
        .advantage-item { 
            .advantage-item-text {
                width: 60%; 
            }
            .advantage-item-img {
                width: 40%;     
            }
        }

    }
    .advantage-about{
    background: var(--bg-top);
    .advantage-about-title{
        padding: 50px 120px 0px 120px;
        
         
    }
    .advantage-about-item{ 
        .advantate-about-card{
            width: 65%; 
        }
        .advantate-about-img{ 
            width: 35%; 
        }
    }
} 
}
@media screen and(max-width:1110px) {
    .advantage-list {
    padding: 0px;

    .advantage-item { 

        .advantage-item-text {
            width: 55%;
            padding: 60px 20px;

            h1 {
                font-family: Stem-Medium;
                font-size: 24px;
                line-height: 28px;
                font-feature-settings: 'tnum'on, 'lnum'on;
                color: var(--other-black);
            }

            h2 { 
                font-size: 1.125rem;
                line-height: 1.25rem; 
            }

            p { 
                font-size: 1rem;
                line-height: 1.125rem; 
            }
        }

        .advantage-item-img {
            width: 45%;
            padding: 60px; 
        }
    }

}
}
@media screen and(max-width:768px){
   .advantage-list { 

    .advantage-item { 
        flex-wrap: wrap;  
        .advantage-item-text {
            width: 100%;
            padding: 20px;
            
 
        }

        .advantage-item-img {
            width: 100%;
            padding: 60px;            
        }
    }
    .advantage-item:nth-child(odd){
        flex-direction: column-reverse;
    }
    .advantage-item:nth-child(even){
        flex-direction: column-reverse;
    }

} 
.advantage-about{ 
    .advantage-about-title{
        padding: 50px 20px 0px 20px;
        
        h1{
            font-family: Stem-Medium;
            font-size: 32px;
            line-height: 36px; 
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: var(--other-black);
        }
    }
    .advantage-about-item{ 
        flex-wrap: wrap; 
        .advantate-about-card{
            width: 100%;
            padding: 50px 20px;
        }
        .advantate-about-img{
            display: flex;
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 50px; 
            img{
                max-width: 100%;
            }
        }
    }
}
}
</style>
